import classNames from 'classnames';
import React from 'react';
import { FilterType } from '../../enums';

import { HeaderInfo, OnFilter, OnSort, SortInfo, TableFilters } from '../../types';
import TableHeaderCell from '../TableHeaderCell/TableHeaderCell';

import styles from './TableHeader.module.scss';

interface Props {
  headers: HeaderInfo[];
  className?: string;
  sort?: SortInfo;
  filters?: TableFilters;
  onSort: OnSort;
  onFilter?: OnFilter;
}

export default function TableHeader({
  headers,
  sort,
  className,
  filters = {},
  onSort,
  onFilter,
}: Props): React.ReactElement {
  return (
    <thead className={classNames(styles.root, className)}>
      <tr>
        {headers.map((header) => (
          <TableHeaderCell
            key={header.fieldKey || header.displayName}
            header={header}
            sort={sort}
            filterInfo={{
              options: header.filterOptions,
              type: header.filterType || FilterType.text,
            }}
            filterValue={filters[header.fieldKey]}
            onSort={onSort}
            onFilter={onFilter}
          ></TableHeaderCell>
        ))}
      </tr>
    </thead>
  );
}
