import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import md5 from 'md5';
import styles from './ProfilePicture.module.scss';
import { ReactComponent as CameraIcon } from 'static/icons/camera.svg';

interface Props {
  src: string | null;
  email: string;
  uploadedImage: File | null;
  setUploadedImage: (image: File) => void;
}

const ProfilePicture: FC<Props> = ({ src, email, uploadedImage, setUploadedImage }) => {
  const inputFileButtonRef = useRef<HTMLInputElement>(null);
  const [uploadedImageSrc, setUploadedImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (!uploadedImage) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(uploadedImage);
    reader.onload = () => setUploadedImageSrc(reader.result as string);
  }, [uploadedImage]);

  const handleOverlayClick = useCallback(() => {
    const buttonElement = inputFileButtonRef.current;
    if (!buttonElement) {
      return;
    }
    buttonElement.click();
  }, [inputFileButtonRef]);

  const onFileSelect = useCallback((e) => {
    const file = e.target.files[0];
    setUploadedImage(file);
  }, []);

  const imgSrc = uploadedImageSrc || src || `//www.gravatar.com/avatar/${md5(email)}?s=50&d=identicon`;

  return (
    <div className={styles.root}>
      <img src={imgSrc} className={styles.userAvatar} />
      <div className={styles.overlay} onClick={handleOverlayClick}>
        <CameraIcon className={styles.editIcon}></CameraIcon>
        <input
          ref={inputFileButtonRef}
          onChange={onFileSelect}
          type="file"
          accept="image/png, image/jpeg"
          className={styles.hidden}
        />
      </div>
    </div>
  );
};

export default memo(ProfilePicture);
