import classNames from "classnames";
import React, {FC, memo, useCallback, useEffect, useState} from 'react';

import styles from './Company.module.scss';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import PageBlock from 'shared/components/PageBlock/PageBlock';
import {CompanyHeader} from './components/CompanyHeader/CompanyHeader';
import {Tabs, Tab} from 'shared/components/Tabs';
import {getCurrentOrganisation, setCurrentOrgId} from 'redux/features/organisation';
import {useDispatch, useSelector} from 'react-redux';
import {BusinessInfo} from './components/BusinessInfo';
import {MainOfficeAddress} from './components/MainOfficeAddress';
import {Billing} from './components/Billing';
import {PrimaryContact} from './components/PrimaryContact';
import {getOrganisationFormEntity, onValueSubmit} from './helpers';
import {Form, Formik} from 'formik';
import Button from 'shared/components/Button/Button';
import jwtDecode from "jwt-decode";
import cookie from "js-cookie";
import {USER_TOKEN_COOKIE} from "../../constants/authConstants";
import {fetchToken} from "../../api/user";

const CompanyComponent: FC = () => {

    const subNavList = ['Business info', 'Main Office Address', 'Billing', 'Primary Contact'];

    const [tabIndex, setTabIndex] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch();
    const currentOrganisation = useSelector(getCurrentOrganisation);
    const company = currentOrganisation ? getOrganisationFormEntity(currentOrganisation) : null;

    const onFormValueSubmit = useCallback(
        (value) => {
            setIsSubmitting(true);
            onValueSubmit(value, dispatch);
        },
        [dispatch]
    );
    const currentUserTokenData: any = jwtDecode(cookie.get(USER_TOKEN_COOKIE) || '');
    useEffect(() => {

        if ((!currentUserTokenData.data && !currentUserTokenData.data.sub) && currentOrganisation) {
            const orgId = currentOrganisation.organisation_id;
            dispatch(setCurrentOrgId(orgId));
            setTimeout(() => {
                fetchToken().then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 22)
                });
            }, 22)
        }

        setIsSubmitting(false);
    }, [currentOrganisation]);


    return (
        <div className={styles.root}>

            <div className={styles.companySidebarNav}>
                <ul className={styles.sidebarNavList}>
                    {subNavList.map((navItem, navItemIndex) => (
                        <li
                            className={classNames(styles.sidebarNavListItem, {
                            [styles.sidebarNavListItemActive]: navItemIndex === tabIndex
                            })}
                            onClick={() => {
                                setTabIndex(navItemIndex)
                            }}
                        >
                            {navItem}
                        </li>
                    ))}
                </ul>
            </div>

            <div className={styles.companyPageContent}>
                <PageBlock>
                    {company && currentOrganisation && (
                        <>
                            <div className={styles.companyHeaderContainer}>
                                <CompanyHeader company={currentOrganisation}></CompanyHeader>
                            </div>
                            <Formik enableReinitialize initialValues={company}
                                    onSubmit={(values) => onFormValueSubmit(values)}>
                                {({dirty}) => (
                                    <Form className={styles.form}>

                                        {tabIndex === 0 && (
                                            <BusinessInfo></BusinessInfo>
                                        )}
                                        {tabIndex === 1 && (
                                            <MainOfficeAddress></MainOfficeAddress>
                                        )}
                                        {tabIndex === 2 && (
                                            <Billing></Billing>
                                        )}
                                        {tabIndex === 3 && (
                                            <PrimaryContact></PrimaryContact>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )}
                    {(isSubmitting || !company) && <LoadingOverlay></LoadingOverlay>}
                </PageBlock>
            </div>

        </div>
    );
};

export const Company = memo(CompanyComponent);
