import React, { FC, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import zxcvbn from 'zxcvbn';
import classNames from 'classnames';
import Button from "../../../../shared/components/Button/Button";
import Input from "../../../../shared/components/Input/Input";
import InputField from "../../../../shared/components/InputField/InputField";
import PasswordStrengthMeter from "../../../../shared/components/PasswordStrengthMeter/PasswordStrengthMeter";

import { registerUser } from 'redux/features/user';
import { RootState } from 'redux/store';
import { setErrorMessage } from 'pages/RegisterUser/redux';
import cookie from 'js-cookie';
import size from 'lodash/size';
import styles from './RegisterForm.module.scss';

// interface Props {
//     inviteToken: string;
//     email: string;
// }

const RegisterFormComponent = () => {
    const initialFormValues = {
        information: {
            first_name: '',
            last_name: '',
        },
        password: '',
        password_again: '',
    };

    const [termsAccepted, setTermsAccepted] = useState(false);
    const [passwordToValidate, setpasswordToValidate] = useState('');
    const [showPass, setShowPass] = useState(!!cookie.get('provider') && cookie.get('provider') === 'xelacore' || false);

    const dispatch = useDispatch();
    const errorMessage = useSelector<RootState, string | undefined>((state) => state.registerUserPage.errorMessage);

    const onFormValueSubmit = useCallback((formValue) => {
        if (!size(formValue.information.first_name) || !size(formValue.information.last_name)) {
            dispatch(setErrorMessage('First and last name are mandatory fields'));
            return;
        }

        if (formValue.password !== formValue.password_again) {
            dispatch(setErrorMessage('Passwords do not match'));
            return;
        }
        const registerUserEntity = { ...formValue };
        delete registerUserEntity.password_again;

        if (!showPass) {
            delete registerUserEntity.password;
        }

        dispatch(registerUser({ registerUserEntity }));
    }, []);

    const checkTermsAccepted = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTermsAccepted(e.target.checked)
    }

    const handleFormChange = (event: any) => {
        const { name, value } = event.target;

        if (name === 'password') {
            setpasswordToValidate(value);
        }
    }

    return (
        <Formik enableReinitialize
            initialValues={initialFormValues}
            onSubmit={(values) => onFormValueSubmit(values)}>
            {() => (
                <Form className={styles.root} onChange={(e) => handleFormChange(e)}>
                    <InputField fieldName="First Name">
                        <Input name="information.first_name" ></Input>
                    </InputField>
                    <InputField fieldName="Last Name">
                        <Input type="text" name="information.last_name"></Input>
                    </InputField>
                    {showPass && (
                        <>
                            <InputField fieldName="Password">
                                <>
                                    <Input type="password" name="password"></Input>
                                    <PasswordStrengthMeter password={passwordToValidate}></PasswordStrengthMeter>
                                </>
                            </InputField>
                            <InputField fieldName="Repeat Password">
                                <Input type="password" name="password_again"></Input>
                            </InputField>
                        </>
                    )}

                    <div className={styles.termsCheckbox}>
                        <label>
                            <input onChange={checkTermsAccepted} name="terms_accepted" type="checkbox" />
                            <span>I accept the <a target='_blank' href="https://xelacore.zendesk.com/hc/en-gb/articles/4417717599377">Terms & Conditions</a></span>
                        </label>
                    </div>

                    <p className={classNames(styles.errorMessage, { [styles.hidden]: !errorMessage })}>{errorMessage}</p>
                    <div className={styles.submitButtonContainer}>
                        <Button
                            disabled={!termsAccepted || (!!showPass && zxcvbn(passwordToValidate).score < 3)}
                            large action type="submit"
                        >
                            Create Account
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export const RegisterForm = memo(RegisterFormComponent);
