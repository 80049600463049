import Organisation from 'interfaces/organisation';
import React, {FC, memo} from 'react';
import styles from './CompanyHeader.module.scss';

interface Props {
    company: Organisation;
}

const CompanyHeaderComponent: FC<Props> = ({company}) => {
    // @todo create avatar/image-placeholder component
    const companyImagePlaceholder = `https://eu.ui-avatars.com/api/?name=${company.name}}`
    return (
        <div className={styles.root}>
            <img className={styles.companyLogo} src={companyImagePlaceholder} alt={company.name}/>
            <h3 className={styles.companyName}>{company.name}</h3>
        </div>
    );
};

export const CompanyHeader = memo(CompanyHeaderComponent);
