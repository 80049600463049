import React, { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {getEmployees, setCurrentOrgInfo} from 'redux/features/organisation';
import PageBlock from 'shared/components/PageBlock/PageBlock';
import {
    getEmployeesTableData,
    getEmployeesTableDataWithPagination,
    getFilters,
    getPagination,
    getSort,
} from './redux/employeesTable/selectors';
import Table from 'shared/components/Table/Table';
import {setFilter, setPageNumber, setRowsPerPage, setSort} from './redux/employeesTable';
import {Employee} from 'interfaces/employee';
import {employeesTableHeaders} from './constants';
import {getTableRows} from './helpers';
import {InviteUser} from './components/InviteUser';
import {inviteUser} from './redux/thunks';
import {RootState} from 'redux/store';
import {clearError} from './redux';

import styles from './Team.module.scss';
import cookie from 'js-cookie';
import {USER_TOKEN_COOKIE} from '../../constants/authConstants';
import {OrganisationInfo} from '../../redux/features/organisation/types';
import jwtDecode from 'jwt-decode';

const TeamComponent: FC = () => {
    const dispatch = useDispatch();
    const employeesTableDataPaginated = useSelector(getEmployeesTableDataWithPagination);
    const employeesTableData = useSelector(getEmployeesTableData);
    const sort = useSelector(getSort);
    const filters = useSelector(getFilters);
    const {rowsPerPage, pageNumber} = useSelector(getPagination);
    const canInviteUsers = useSelector<RootState, boolean>(
        (state) => !!state.organisation.currentOrgInfo?.permissions.system?.users.can.includes('invite')
    );
    const inviteErrorMessage = useSelector<RootState, string | null | undefined>((state) => state.teamPage.errorMessage);
    const onNumberOfRowsSelected = useCallback((numberOfRows) => dispatch(setRowsPerPage(numberOfRows)), [dispatch]);
    const onPageSelected = useCallback((pageNumber) => dispatch(setPageNumber(pageNumber)), [dispatch]);
    const onSort = useCallback((sort) => dispatch(setSort(sort)), [dispatch]);
    const onFilter = useCallback((filter) => dispatch(setFilter(filter)), [dispatch]);

    useEffect(() => {
        dispatch(getEmployees());
    }, []);

    useEffect(() => {
        const jwt = cookie.get(USER_TOKEN_COOKIE);
        if (jwt) {
            const info: { data: OrganisationInfo } = jwtDecode(jwt);
            dispatch(setCurrentOrgInfo(info.data));
        }
    })

    const onUserInvite = useCallback(
        (email: string, firstName: string, lastName: string) => {
            const params = {
                email,
                firstName,
                lastName
            };

            dispatch(inviteUser(params));
        },
        [dispatch]
    );

    const rows = getTableRows(employeesTableDataPaginated as unknown as Employee[]);

    const tableProps = {
        rows,
        sort,
        filters,
        pageNumber,
        rowsPerPage,
        totalRows: employeesTableData.length,
        headers: employeesTableHeaders,
        onSort,
        onFilter,
        onPageSelected,
        onNumberOfRowsSelected,
    };

    return (
        <PageBlock>
            <div className={styles.root}>
                <Table {...tableProps}></Table>
            </div>
            {canInviteUsers && (
                <div className={styles.inviteButtonHolder}>
                    <InviteUser
                        errorMessage={inviteErrorMessage}
                        onEmailSubmit={onUserInvite}
                        onValueChange={() => dispatch(clearError())}
                    ></InviteUser>
                </div>
            )}
        </PageBlock>
    );
};

export const Team = memo(TeamComponent);
