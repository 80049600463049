import React, {FC, memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import styles from './MyProfile.module.scss';
import {User} from 'interfaces/user';
import {getUser, updateUser} from 'redux/features/user';
import {RootState} from 'redux/store';
import PageBlock from 'shared/components/PageBlock/PageBlock';
import MainInfo from './components/MainInfo/MainInfo';
import ProfileHeader from './components/ProfileHeader/ProfileHeader';
import Button from 'shared/components/Button/Button';
import {Form, Formik} from 'formik';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

const MyProfileComponent: FC = () => {
    const dispatch = useDispatch();
    const user = useSelector<RootState, User | null>((state) => state.user.user);
    const [uploadedImage, setUploadedImage] = useState<File | null>(null);
    const [isNameEdited, setIsNameEdited] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorForm, setErrorForm] = useState(false);

    useEffect(() => {
        dispatch(getUser());
    }, []);

    useEffect(() => {
        setIsNameEdited(false);
        setIsSubmitting(false);
        setErrorForm(false);
        setUploadedImage(null);
    }, [user]);
    
    return (
        <div className={styles.root}>
            <PageBlock>
                {user && (
                    <Formik
                        enableReinitialize
                        initialValues={user}
                        onSubmit={(values) => {
                            values.information.title = values.information.title || '';
                            values.information.title = values.information.last_name || '';
                            
                            dispatch(updateUser({user: values, image: uploadedImage}));
                        }}
                    >
                        {({dirty}) => (
                            <Form className={styles.form}>
                                <ProfileHeader
                                    uploadedImage={uploadedImage}
                                    setUploadedImage={setUploadedImage}
                                    isNameEdited={isNameEdited}
                                    setIsNameEdited={setIsNameEdited}
                                    user={user}
                                    setErrorForm={(value) => setErrorForm(value)}
                                ></ProfileHeader>

                                <MainInfo></MainInfo>

                                <div className={styles.formActions}>
                                    <Button disabled={isSubmitting || (!dirty && !uploadedImage) || !!errorForm} medium action
                                            type="submit">
                                        Save changes
                                    </Button>
                                </div>

                            </Form>
                        )}
                    </Formik>
                )}
                {(isSubmitting || !user) && <LoadingOverlay></LoadingOverlay>}
            </PageBlock>
        </div>
    );
};

export const MyProfile = memo(MyProfileComponent);
