import React, {FC, memo} from 'react';

import Input from 'shared/components/Input/Input';
import InputField from 'shared/components/InputField/InputField';
import {FormikDropdown} from 'shared/components/FormikDropdown';

import styles from './PrimaryContact.module.scss';
import {titleDropdownVaules} from 'pages/constants';

const PrimaryContactComponent: FC = () => {
    return (
        <>
            <div className={styles.pairedFieldsContainer}>
                <div className={styles.titleFieldContainer}>
                    <InputField fieldName="Title">
                        <FormikDropdown isDisabled={true} name="contacts.primary_ambassador.title"
                                        options={titleDropdownVaules}></FormikDropdown>
                    </InputField>
                </div>
                <InputField fieldName="First Name">
                    <Input disabled name="contacts.primary_ambassador.first_name" formikField></Input>
                </InputField>
                <InputField fieldName="Last Name">
                    <Input disabled name="contacts.primary_ambassador.last_name" formikField></Input>
                </InputField>
            </div>
            <InputField fieldName="Email Address">
                <Input disabled name="contacts.primary_ambassador.email" type="email" formikField></Input>
            </InputField>
            <InputField fieldName="Business Phone Number">
                <Input disabled name="contacts.primary_ambassador.biz_phone" formikField></Input>
            </InputField>
        </>
    );
};

export const PrimaryContact = memo(PrimaryContactComponent);
