import React, {FC, memo} from 'react';

import styles from './MainInfo.module.scss';
import InputField from 'shared/components/InputField/InputField';
import Input from 'shared/components/Input/Input';

const MainInfo: FC = () => {
    return (
        <div className={styles.root}>
            <h1 className={styles.infoHeading}>Main Info</h1>
            <div className={styles.fieldsContainer}>
                <InputField fieldName="Position">
                    <Input name="information.job_title" formikField></Input>
                </InputField>
            </div>
            <h1 className={styles.infoHeading}>Contact Info</h1>
            <div className={styles.fieldsContainer}>
                <InputField fieldName="Email">
                    <Input disabled name="email" formikField></Input>
                </InputField>

                <InputField fieldName="Mobile Number">
                    <Input name="information.phone_number" formikField></Input>
                </InputField>
            </div>
        </div>
    );
};

export default memo(MainInfo);
