import React, { FC, memo } from 'react';

import Input from 'shared/components/Input/Input';
import InputField from 'shared/components/InputField/InputField';

const BusinessInfoComponent: FC = () => {
  return (
    <>
      <InputField fieldName="Legal Company Name">
        <Input name="legal_company_name" disabled formikField></Input>
      </InputField>

      <InputField fieldName="Company Registration Number">
        <Input name="company_registration_number" disabled formikField></Input>
      </InputField>

      <InputField fieldName="Business Unit">
        <Input name="business_unit" disabled formikField></Input>
      </InputField>

      <InputField fieldName="Company Number">
        <Input name="company_number" disabled formikField></Input>
      </InputField>

      <InputField fieldName="UK VAT">
        <Input name="uk_vat" disabled formikField></Input>
      </InputField>

      <InputField fieldName="Phone">
        <Input required={true} disabled name="phone" formikField></Input>
      </InputField>
    </>
  );
};

export const BusinessInfo = memo(BusinessInfoComponent);
