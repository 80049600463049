import React, { ChangeEvent, FC, FocusEvent, memo, ReactChild } from 'react';
import styles from './Input.module.scss';
import classNames from 'classnames';
import { Field } from 'formik';

interface Props {
  error?: string | null;
  type?: string;
  name?: string;
  value?: string | number;
  placeholder?: string;
  className?: string;
  autoComplete?: string;
  min?: number;
  max?: number;
  multiline?: boolean;
  required?: boolean;
  disableErrorLabel?: boolean;
  formikField?: boolean;
  autoFocus?:boolean;
  innerRef?: React.ForwardedRef<HTMLInputElement>;
  icon?: ReactChild;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void;
}

const Input: FC<Props> = (props): React.ReactElement => {
  const inputClassName = classNames(styles.input, { [styles.hasError]: !!props.error, [styles.hasIcon]: !!props.icon });
  const inputProps = { ...props };

  delete inputProps.formikField;
  delete inputProps.innerRef;

  return (
    <div className={classNames(styles.root, props.className)}>
      {props.formikField ? (
        <Field {...inputProps} className={inputClassName}></Field>
      ) : (
          <Field  id={inputProps.name}
               ref={props.innerRef} {...inputProps}
               className={inputClassName}
          ></Field>
      )}
      {props.icon && <div className={styles.iconHolder}>{props.icon}</div>}
      {!!props.error && <p className={styles.errorMessage}>{props.error}</p>}
    </div>
  );
};

const MemoizedInput = memo(Input);

export default React.forwardRef((props: Props, forwardedRef: React.ForwardedRef<HTMLInputElement>) => (
  <MemoizedInput {...props} innerRef={forwardedRef} />
));
