import React, { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PageBlock from 'shared/components/PageBlock/PageBlock';
import { FileBlock } from './components/FileBlock';
import { FileInfo } from 'api/files/types';
import { RootState } from 'redux/store';
import { getFilesList } from './redux';

import styles from './Terms.module.scss';

const TermsComponent: FC = () => {
  const dispatch = useDispatch();
  const filesList = useSelector<RootState, FileInfo[]>((state) => state.termsPage.files);

  useEffect(() => {
    dispatch(getFilesList());
  }, []);

  return (
    <div className={styles.root}>
      <PageBlock>
        <div className={styles.content}>
          <div className={styles.fileBlocksContainer}>
            {filesList.map((fileInfo) => (
              <FileBlock key={fileInfo.md5} fileInfo={fileInfo}></FileBlock>
            ))}
          </div>
        </div>
      </PageBlock>
    </div>
  );
};

export const Terms = memo(TermsComponent);
