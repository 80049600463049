import React, { FC, memo } from 'react';

import Input from 'shared/components/Input/Input';
import InputField from 'shared/components/InputField/InputField';

import { FormikDropdown } from 'shared/components/FormikDropdown';
import { countries } from 'constants/countries';

const MainOfficeAddressComponent: FC = () => {
  return (
    <>
      <InputField fieldName="Street Address">
        <Input name="address.street" disabled formikField></Input>
      </InputField>

      <InputField fieldName="City">
        <Input name="address.town" disabled formikField></Input>
      </InputField>

      <InputField fieldName="Region">
        <Input name="address.region" disabled formikField></Input>
      </InputField>

      <InputField fieldName="Country">
        <FormikDropdown isSearchable={true} isDisabled={true} name="address.country" options={countries}></FormikDropdown>
      </InputField>

      <InputField fieldName="Postcode / Zipcode">
        <Input name="address.postcode" disabled formikField></Input>
      </InputField>
    </>
  );
};

export const MainOfficeAddress = memo(MainOfficeAddressComponent);
