import React, {ReactNode} from 'react';
import classNames from 'classnames';
import {Employee} from 'interfaces/employee';

import styles from './Team.module.scss';
import md5 from 'md5';

export const getTableRows = (employees: Employee[]): ReactNode[][] => {
    return employees.filter(employee => !!employee.email && !employee.email?.endsWith('@fabacus.com')).map(
        (employee) => getTableRow(employee));
}


export const getTableRow = ({
                                full_name,
                                email,
                                job_title,
                                profile_image,
                                modules,
                                user_id
                            }: Employee): ReactNode[] => [
    <div className={styles.userBlock}>
        {
            <img
                className={styles.profileImage}
                src={profile_image || ''}
                onError={(e) => ((e.target as HTMLImageElement).src = `//www.gravatar.com/avatar/${md5((email || 'sample@mail.com').toString())}?s=50&d=identicon`)}
            />
        }
        <div className={classNames({[styles.userTextContainer]: job_title})}>
            <h2 className={styles.fullName}>{full_name}</h2>
            {job_title && <p className={styles.title}>{job_title}</p>}
        </div>
    </div>,
    <p className={styles.email}>{email}</p>,
    <p className={classNames(styles.status, user_id?styles.active:styles.pendiong)}>{user_id ? 'Active' : 'Pending'}</p>,
    <p className={styles.modules}>{modules.join(', ')}</p>,
];
