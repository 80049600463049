import React, { FC, memo, useCallback, useState } from 'react';
import classnames from 'classnames';

import { DropdownMenu } from 'shared/components/DropdownMenu';
import { ReactComponent as AppsIcon } from 'static/icons/apps.svg';
import { XelacoreModuleDefinition } from 'interfaces/xelacoreModuleDefinition';

import styles from './ModuleSelector.module.scss';

interface Props {
  modules: XelacoreModuleDefinition[];
}

const ModuleSelectorComponent: FC<Props> = ({ modules }) => {
  const navigateToUrl = useCallback((link) => {
    window.location.href = link;
  }, []);
  const [isMenuOpened, setisMenuOpened] = useState(false);
  const onMenuToggle = useCallback(
    (value) => {
      setisMenuOpened(value);
    },
    [setisMenuOpened]
  );

  return (
    <DropdownMenu
      hasArrow={false}
      title={<AppsIcon className={classnames(styles.appsIcon, { [styles.highlighed]: isMenuOpened })}></AppsIcon>}
      menuheader={'Module Selector'}
      options={modules.map(({ name, link, icon }) => ({
        label: (
          <div className={styles.menuItem}>
            {icon}
            <span>{name}</span>
          </div>
        ),
        onClick: () => navigateToUrl(link),
      }))}
      onToggle={onMenuToggle}
    ></DropdownMenu>
  );
};

export const ModuleSelector = memo(ModuleSelectorComponent);
