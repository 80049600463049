import React, { FC, memo, useCallback } from 'react';

import { downloadFile } from 'api/files';
import { downloadFileContent } from 'helpers/fileDonwload';
import { FileInfo } from 'api/files/types';
import { ReactComponent as DownloadIcon } from 'static/icons/download.svg';

import styles from './FileBlock.module.scss';

interface Props {
  fileInfo: FileInfo;
}

const FileBlockComponent: FC<Props> = ({ fileInfo }) => {
  const handleDownloadClick = useCallback(async (fileName) => {
    const file = await downloadFile(fileName);
    downloadFileContent(file.data, fileName);
  }, []);

  return (
    <div className={styles.root}>
      {fileInfo.name}
      <div className={styles.downloadBlock} onClick={() => handleDownloadClick(fileInfo.name)}>
        Download<DownloadIcon className={styles.downloadIcon}></DownloadIcon>
      </div>
    </div>
  );
};

export const FileBlock = memo(FileBlockComponent);
