import React, {useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import classNames from 'classnames';
import noop from 'lodash/noop';

import ColumnFilter from '../FIlterPanel/ColumnFilter';
import {FilterInfo, HeaderInfo, OnFilter, OnSort, SortInfo} from '../../types';

import {ReactComponent as CaretIconDown} from 'static/icons/caret-down.svg';
import {ReactComponent as FilterIconSolid} from 'static/icons/filter-solid.svg';
import {ReactComponent as SearchIcon} from 'static/icons/search.svg';

import styles from './TableHeaderCell.module.scss';
import {SortDirection} from '../../enums';

interface Props {
    header: HeaderInfo;
    filterInfo: FilterInfo;
    filterValue: string | string[];
    onSort: OnSort;
    sort?: SortInfo;
    onFilter?: OnFilter;
}

const unsortedBlock = (
    <div className={styles.unsortedBlock}>
        <CaretIconDown className={classNames(styles.sortArrow, styles.up, styles.unsortedArrow)}></CaretIconDown>
        <CaretIconDown className={classNames(styles.sortArrow, styles.unsortedArrow)}></CaretIconDown>
    </div>
);

export default function TableHeaderCell({
                                            header: {fieldKey, displayName, filterType, sortable = false},
                                            sort,
                                            onSort,
                                            filterValue,
                                            filterInfo,
                                            onFilter = noop,
                                        }: Props): React.ReactElement {
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
    const filterPanelRef = useRef<HTMLDivElement>(null);
    const filterContainerRef = useRef<HTMLDivElement>(null);

    const clickHandler = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        if (!filterPanelRef.current?.contains(target)) {
            closePanel();
        }
    };

    const closePanel = () => {
        document.removeEventListener('mousedown', clickHandler);
        document.removeEventListener('keypress', enterKeyPressedhandler);
        setIsFilterPanelOpen(false);
    };

    const handleFilterIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        setIsFilterPanelOpen((isOpen) => !isOpen);
        document.addEventListener('keypress', enterKeyPressedhandler);
        document.addEventListener('mousedown', clickHandler);
    };

    const enterKeyPressedhandler = (e: KeyboardEvent) => {
        if (e.code === '13') {
            closePanel();
        }
    };

    useEffect(() => {
        document.addEventListener('keypress', enterKeyPressedhandler);
        return () => document.removeEventListener('keypress', enterKeyPressedhandler);
    });

    useEffect(() => {
        return closePanel;
    }, []);

    const isFilterSet = filterValue && (Array.isArray(filterValue) ? filterValue && filterValue.length : filterValue);

    return (
        <th className={styles.root} key={displayName}>
            <div>
                {sortable && sort && (
                    <div className={classNames(styles.iconHolder, styles.sortHolder)}
                         onClick={() => onSort(fieldKey)}>
                        {sort.fieldKey !== fieldKey ? (
                            unsortedBlock
                        ) : (
                            <CaretIconDown
                                className={classNames(styles.sortArrow, {
                                    [styles.up]: sort.direction === SortDirection.asc,
                                    [styles.down]: sort.direction === SortDirection.desc,
                                })}
                            ></CaretIconDown>
                        )}
                    </div>
                )}
                <p>{displayName}</p>
                {filterType && (
                    <div ref={filterContainerRef} className={classNames(styles.iconHolder, styles.filterHolder)}>
                        <div onClick={(e) => handleFilterIconClick(e)}>
                            {isFilterSet ? (
                                <FilterIconSolid className={classNames(styles.filterIcon)}></FilterIconSolid>
                            ) : (
                                <SearchIcon className={classNames(styles.filterIcon)}></SearchIcon>
                            )}
                        </div>
                        {isFilterPanelOpen && (
                            <>
                                {createPortal(
                                    <ColumnFilter
                                        ref={filterPanelRef}
                                        onChange={(value) => onFilter({fieldKey, value})}
                                        filterValue={filterValue}
                                        filterInfo={filterInfo}
                                        position={{
                                            top: filterContainerRef.current?.getBoundingClientRect().y || 0,
                                            left: filterContainerRef.current?.getBoundingClientRect().x || 0,
                                        }}
                                    ></ColumnFilter>,
                                    filterPanelRef.current?.closest('.table-root') || document.body
                                )}
                                {createPortal(
                                    <div className={styles.overlay}></div>,
                                    filterPanelRef.current?.closest('tr') || document.body
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </th>
    );
}
