import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './ProfileHeader.module.scss';
import { User } from 'interfaces/user';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import { ReactComponent as EditIcon } from 'static/icons/edit.svg';
import { ReactComponent as CloseIcon } from 'static/icons/cross-small.svg';
import size from 'lodash/size';
import { Field } from 'formik';
import classNames from 'classnames';

interface Props {
  user: User;
  isNameEdited: boolean;
  uploadedImage: File | null;
  setIsNameEdited: (isNameEdited: boolean) => void;
  setErrorForm: (value: boolean) => void;
  setUploadedImage: (image: File) => void;
}

const ProfileHeader: FC<Props> = ({ user, isNameEdited, uploadedImage, setIsNameEdited, setUploadedImage, setErrorForm }) => {
  const dispatch = useDispatch();
  const setNameEdited = useCallback(() => {
    setIsNameEdited(true);
  }, [setIsNameEdited]);
  const cancelNameEdited = useCallback(() => {
    setIsNameEdited(false);
  }, [setIsNameEdited]);
  const isError = useCallback(() => {
    setErrorForm(true);
  }, [setErrorForm]);
  const removeError = useCallback(() => {
    setErrorForm(false);
  }, [setErrorForm]);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');


  const validate = (value: string, field: string) => {
    let errorMessage;

    if (!size(value)) {
      errorMessage = `${field === 'first_name' ? 'First' : 'Last'} Name is required.`;
    }

    field === 'first_name' ? setFirstNameError(errorMessage || '') : setLastNameError(errorMessage || '');
  };

  useEffect(() => {
    if (!!firstNameError || !!lastNameError) {
      dispatch(isError);
    } else {
      dispatch(removeError);
    }
  }, [firstNameError, lastNameError])

  return (
    <div className={styles.root}>
      <ProfilePicture
        uploadedImage={uploadedImage}
        setUploadedImage={setUploadedImage}
        src={user.information.profile_image}
        email={user.email}
      ></ProfilePicture>
      <div className={styles.userInfoContainer}>
        <div className={`${styles.usernameContainer} ${isNameEdited?styles.editMode:''}`}>
          {isNameEdited ? (
            <>
              <div>
                <Field 
                  name="information.first_name" 
                  required 
                  placeholder="First Name" 
                  validate={(value: string) => validate(value, 'first_name')} 
                  className={classNames(styles.input, {[styles.hasError]: !!firstNameError})}>
                </Field>
                {!!firstNameError && (<div className={styles.errorMessage}>{firstNameError}</div>)}
              </div>

              <div>
                <Field 
                  name="information.last_name" 
                  required 
                  placeholder="Last Name" 
                  validate={(value: string) => validate(value, 'last_name')} 
                  className={classNames(styles.input, { [styles.hasError]: !!lastNameError })}>
                </Field>
                {!!lastNameError && (<div className={styles.errorMessage}>{lastNameError}</div>)}
              </div>

              <CloseIcon className={styles.closeIcon} onClick={cancelNameEdited}></CloseIcon>
            </>
          ) : (
            <>
              <h3 className={styles.username}>
                {user.information.first_name} {user.information.last_name}
              </h3>
              <EditIcon className={styles.editIcon} onClick={setNameEdited}></EditIcon>
            </>
          )}
        </div>
        <p className={styles.userTitle}>{user.information.job_title}</p>
      </div>
    </div>
  );
};

export default memo(ProfileHeader);
