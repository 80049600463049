import {Form, Formik} from "formik";
import React, {FC, memo} from 'react';
import noop from 'lodash/noop';
import {useDispatch} from "react-redux";

import Input from 'shared/components/Input/Input';
import {clearError} from "../../../Team/redux";
import styles from './RegistrationProject.module.scss';

interface Props {
    aliases: string[];
    gcpList: string[];
    organisationName: string;
    gtinWaiver: boolean;
}

const RegistrationProjectComponent: FC<Props> = ({aliases, gcpList, organisationName, gtinWaiver}) => {
    const dispatch = useDispatch();
    return (
        <div className={styles.root}>
            <Formik
                initialValues={{aliases, gcpList, organisationName}}
                onSubmit={() => {
                    () => dispatch(clearError())
                }}
            >
                <Form className={styles.form}>

                    <h1 className={styles.organisationName}>{organisationName}</h1>
                    <div className={styles.divider}></div>
                    <h2 className={styles.inputGroupTitle}>Doing business as</h2>
                    <div className={styles.inputGroup}>
                        {aliases.map((alias) => (
                            <Input key={alias} value={alias} onChange={noop} disabled></Input>
                        ))}
                    </div>

                    <h2 className={styles.inputGroupTitle}>GCP</h2>
                    <div className={styles.inputGroup}>
                        {gcpList.map((gcp) => (
                            <Input key={gcp} value={gcp} onChange={noop} disabled></Input>
                        ))}
                    </div>

                    <h2 className={styles.inputGroupTitle}>GTIN Settings</h2>
                    <h3 className={styles.label}>GTIN Extempt</h3>
                    <Input value={gtinWaiver ? 'YES' : 'NO'} onChange={noop} disabled></Input>


                </Form>
            </Formik>
        </div>
    );
};

export const RegistrationProject = memo(RegistrationProjectComponent);
