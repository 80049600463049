import React from 'react';

import styles from './TableBody.module.scss';

interface Props {
  rows: React.ReactNode[][];
}

export default function TableBody({ rows }: Props): React.ReactElement {
  return (
    <tbody className={styles.root}>
      {rows.map((cols, i) => (
        <tr className={styles.tableRow} key={i}>
          {cols.map((col, i) => (
            <td key={i} className={styles.tableCell}>
              {col}
            </td>
          ))}
        </tr>
      ))}
      {rows.length === 0 && <tr className={styles.emptyTableRow}></tr>}
    </tbody>
  );
}
