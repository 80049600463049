import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import organisationReducer from './features/organisation/reducers';
import userReducer from './features/user/reducers';
import notificationsReducer from './features/notifications/reducers';

import employeesTableReducer from 'pages/Team/redux/employeesTable/reducers';
import brandsPageReducer from 'pages/Brands/redux/reducers';
import settingsPageReducer from 'pages/Settings/redux/reducers';
import termsPageReducer from 'pages/Terms/redux/reducers';
import teamPageReducer from 'pages/Team/redux/reducers';
import registerUserPageReducer from 'pages/RegisterUser/redux/reducers';

declare let process: { env: { REACT_APP_STAGE: string } };

export const store = configureStore({
  reducer: {
    organisation: organisationReducer,
    user: userReducer,
    notifications: notificationsReducer,
    employeesTable: employeesTableReducer,
    brandsPage: brandsPageReducer,
    settingsPage: settingsPageReducer,
    termsPage: termsPageReducer,
    teamPage: teamPageReducer,
    registerUserPage: registerUserPageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    process.env.REACT_APP_STAGE === 'development' ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
