import { NavigationItem } from 'interfaces/uiConstants';

export const navigationList: NavigationItem[] = [
  {
    name: 'My Profile',
    paths: ['/'],
  },
  {
    name: 'Company',
    paths: ['/company'],
  },
  {
    name: 'Team',
    paths: ['/team'],
  },
  {
    name: 'Settings',
    paths: ['/settings'],
  },
  {
    name: 'Terms & Contracts',
    paths: ['/terms'],
  },
];
