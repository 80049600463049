import React, {FC, memo, useCallback, useState} from 'react';
import classNames from 'classnames';
import Button from 'shared/components/Button/Button';
import Input from 'shared/components/Input/Input';
import {clearError} from "../../redux";

import styles from './InviteUser.module.scss';
import {Formik} from 'formik';

interface Props {
    onEmailSubmit: (email: string, firstName: string, lastName: string) => void;
    onValueChange: () => void;
    errorMessage?: string | null;
}

const InviteUserComponent: FC<Props> = ({onEmailSubmit, errorMessage, onValueChange}) => {
    const [isInviteUiShown, setIsInviteUiShown] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const onFormSubmit = useCallback(
        (e) => {
            e.preventDefault();
            onEmailSubmit(inviteEmail, firstName, lastName);
            setIsInviteUiShown(false)
        },
        [onEmailSubmit, inviteEmail, firstName, lastName]
    );

    const disabledForm = () => {
        return inviteEmail.length === 0 || firstName.length === 0 || lastName.length === 0;
    }

    return (
        <div className={styles.root}>
            <button className={styles.inviteButton} onClick={() => setIsInviteUiShown((value) => !value)}>
                <span>+</span>
            </button>
            {isInviteUiShown && (
                <Formik
                    initialValues={{firstName, lastName, inviteEmail}}
                    onSubmit={onFormSubmit}
                >
                    <form onSubmit={onFormSubmit}
                          className={styles.inviteFormContainer}>
                        <p className={styles.formLabel}>
                            Invite user:
                        </p>

                        <div className={styles.nameBox}>
                            <div className={classNames(styles.inputBox, styles.required)}>
                                <Input
                                    type="text"
                                    required
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => {
                                        onValueChange();
                                        setFirstName(e.target.value);
                                    }}
                                    error={errorMessage}
                                ></Input>
                            </div>

                            <div className={classNames(styles.inputBox, styles.required)}>
                                <Input
                                    type="text"
                                    required
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => {
                                        onValueChange();
                                        setLastName(e.target.value);
                                    }}
                                    error={errorMessage}
                                ></Input>
                            </div>
                        </div>


                        <div className={classNames(styles.inputBox, styles.required)}>
                            <Input
                                type="email"
                                required
                                placeholder="Enter user email"
                                value={inviteEmail}
                                onChange={(e) => {
                                    onValueChange();
                                    setInviteEmail(e.target.value);
                                }}
                                error={errorMessage}
                            ></Input>
                        </div>

                        <div className={styles.inviteFormControlsContainer}>
                            <Button action disabled={disabledForm()} type="submit">
                                Invite
                            </Button>
                            <Button danger2 onClick={() => setIsInviteUiShown(false)}>Close</Button>
                        </div>
                    </form>
                </Formik>
            )}
        </div>
    );
};

export const InviteUser = memo(InviteUserComponent);
