import React, { FC, memo, useEffect, useState } from 'react';
import { NavigationItem } from 'interfaces/uiConstants';
import styles from './NavigationMenu.module.scss';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';

interface Props {
  navigationItems: NavigationItem[];
}

const NavigationMenu: FC<Props> = ({ navigationItems }) => {
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState(history.location.pathname);

  useEffect(() => history.listen(({ pathname }) => setCurrentPath(pathname)), [history]);

  return (
    <div className={styles.root}>
      <div className={styles.optionsHolder}>
        {navigationItems.map(({ name, paths }) => (
          <Link
            className={classNames(styles.navLink, {
              [styles.currentPath]: paths.some((p) => currentPath === p),
            })}
            key={name}
            to={paths[0]}
          >
            {name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default memo(NavigationMenu);
