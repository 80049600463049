import React, {FC, memo} from 'react';

import Input from 'shared/components/Input/Input';
import InputField from 'shared/components/InputField/InputField';

const BillingComponent: FC = () => {
    return (
        <>
            <InputField fieldName="Billing Street Address">
                <Input disabled name="billing_address.street" formikField></Input>
            </InputField>

            <InputField fieldName="Billing City">
                <Input disabled name="billing_address.town" formikField></Input>
            </InputField>

            <InputField fieldName="Billing Region">
                <Input disabled name="billing_address.region" formikField></Input>
            </InputField>

            <InputField fieldName="Billing Country">
                <Input disabled name="billing_address.country" formikField></Input>
            </InputField>

            <InputField fieldName="Billing Postcode / Zipcode">
                <Input disabled name="billing_address.postcode" formikField></Input>
            </InputField>
        </>
    );
};

export const Billing = memo(BillingComponent);
