import React, { FC, memo, ReactChild } from 'react';
import styles from './InputField.module.scss';

interface Props {
  children: ReactChild;
  fieldName: string;
}

const InputField: FC<Props> = ({ children, fieldName }) => {
  return (
    <div className={styles.root}>
      <p className={styles.fieldLabel}>{fieldName}</p>
      {children}
    </div>
  );
};

export default memo(InputField);
